<script>
import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import Swal from 'sweetalert2'

import * as XLSX from 'xlsx';



import { ref } from "vue";


/**
 * Dashboard Component
 */
export default {
  setup() {
       
        const doesAddOpenIndicateur = ref(false);
        const doesEditOpenIndicateur = ref(false);
        const doesDeleteOpenIndicateur = ref(false);



        return {
           
            doesAddOpenIndicateur,
            doesEditOpenIndicateur,
            doesDeleteOpenIndicateur,

            
        };
    },
    page: {
    title: "Secteur",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Secteur",
      items: [
        {
          text: "Menu",
          href: "/",
        },
        {
          text: "Secteur",
          active: true,
        },
        {
          text: "Un Secteur",
          active: true,
        },
      ],
      sector: {},
      id: "",
      parameter: {},
      parameters: {},
      type: "",
      records: {},
      obligation: "",
      libelle: "",
      STORAGE_URL: Helper.STORAGE_URL,
    };
  },
  methods: {
    getItem() {
      fetch(Helper.route('entities/'+this.id), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        console.log(data.data);
        this.sector = data.data
        console.log(this.sector)
        console.log("Color", this.sector.projectform.projectformparameters)
        this.parameters = this.sector.projectform.projectformparameters
        this.records = this.sector.projectform.response_forms
      })
    },


    //indicateur

    addItemIndicateur() {
      this.loading = true
      this.errors = []
      let formData = new FormData()
      formData.append('libelle', this.libelle)
      formData.append('type', this.type)
      formData.append('obligation', this.obligation)
      formData.append('project_form_id', this.sector.projectform.id)
      
      fetch(Helper.route('parameters'), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        if (this.status == 200 || this.status == 201 || this.status== 204) {
          this.doesAddOpenIndicateur = false
          this.initDataIndicateur(null)
          this.parameters.push(data.data)
          Swal.fire("Paramètre ajouté !", "Le paramètre a été enregistré avec succès.", "success");
        } else {
          //this.errors.entity_id = Helper.getError(data.data, "entity_id")
          //this.errors.file = Helper.getError(data.data, "file")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },

    editItemIndicateur() {
      this.loading = true
      this.errors = []
      let formData = new FormData()
      formData.append('_method', "PUT")
      formData.append('libelle', this.libelle)
      formData.append('type', this.type)
      formData.append('obligation', this.obligation)
      formData.append('project_form_id', this.sector.projectform.id)

      
      fetch(Helper.route('parameters/'+this.parameter.id), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        console.log('last',this.parameter);
        console.log('new',data.data);
        this.loading = false
        if (this.status == 200 || this.status == 201 || this.status== 204) {
          this.name = ""
          this.doesEditOpen = false
          this.parameters = this.parameters.map(i => i.id !== this.parameter.id ? i : data.data)
          Swal.fire("Paramètre modifier !", "Le paramètre a été modifié avec succès.", "success");
        } else {
            this.errors.libelle = Helper.getError(data.data, "libelle")
            this.errors.value = Helper.getError(data.data, "value")
            
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },

    deleteItIndicateur() {
      this.loading = true
      console.log(this.parameter.id);
      fetch(Helper.route('parameters/'+this.parameter.id), Helper.requestOptions("DELETE"))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.text()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        this.doesDeleteOpenIndicateur = false
        if (this.status == 204) {
          this.parameters = this.parameters.filter(i => i.id !== this.parameter.id)
          Swal.fire("Paramètre supprimer !", "Le paramètre a été supprimé avec succès.", "success");
        } else {
          Swal.fire("Paramètre non supprimer ?", "La suppression n'a pas marché, veuillez réessayez plus tard.", "error");
        }
        this.parametre = null
      })
    },

    initItemIndicateur(parameter, action="edit") {
      this.parameter = parameter
      this.initDataIndicateur(parameter)
      //this.libelle = parameter.libelle
      if (action == "delete") {
        this.doesDeleteOpenIndicateur = true
      } else {
        this.doesEditOpenIndicateur = true
      }
    },

    initDataIndicateur(item = null) {
      this.libelle = item != null ? item.libelle : ""
      this.type = item != null ? item.type : ""
      this.obligation = item != null ? item.obligation : ""
    },


    downloadExcel() {
        // Assuming list_parameters is available in the context
        const list_parameters = this.parameters; // Make sure list_parameters is defined and accessible

        // Create headers based on list_parameters
        const headers = {
            Projet: 'Projet',
            Date: 'Date',
            ...Object.fromEntries(list_parameters.map(param => [param.libelle, param.libelle]))
        };

        // Create data rows
        const dataRows = this.records.map(record => {
            let data = {
                Projet: record.project.name,
                Date: record.created_at,
            };

            // Initialize the rest of the data fields with empty strings
            list_parameters.forEach(param => {
                data[param.libelle] = '';
            });

            // Populate the data fields with actual values
            record.response_form_parameters.forEach(param => {
                const listParam = list_parameters.find(p => p.id === param.project_form_parameter_id);
                if (listParam) {
                    data[listParam.libelle] = param.value;
                }
            });

            return data;
        });

        // Combine headers and data
        const finalData = [headers, ...dataRows];

        // Create worksheet from the final data
        const ws = XLSX.utils.json_to_sheet(finalData, { skipHeader: true });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Données");

        // Create an Excel file
        XLSX.writeFile(wb, "enregistrements.xlsx");
    }



  },
  mounted() {
    this.id = this.$route.params.id
    this.getItem()
  },

  watch: {
    doesAddOpenIndicateur: function() {
      this.errors = []
    },
    doesEditOpenIndicateur: function() {
      this.errors = []
      if (!this.doesEditOpenIndicateur) {
        this.parameter = null
        this.initDataIndicateur(null)
      }
    },

    doesDeleteOpenIndicateur: function() {
      if (!this.doesDeleteOpenIndicateur) {
        this.parameter = null
        this.initDataIndicateur(null)
      }
    },

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <div class="row justify-content-end">
    
      <div class="col-auto mb-2 col-3" >
          <b-button variant="primary" @click="doesAddOpenIndicateur = true" class="ms-auto">
              <i class="bx bx-plus font-size-16 align-middle me-2"></i>
              Ajouter un indicateur
          </b-button>
      </div>

      </div>

    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">À propos du Secteur</h4>
            <p class="card-title-desc mb-3">
              Présentation du secteur <code> {{ sector.name }} </code> de la catégorie <code>{{ sector.category ? sector.category.name : "" }} </code>.
            </p>

            <div class="bg-light p-2 pt-3">
                <h6 class="font-20 text-danger">♣ Les projets du secteur</h6>

                <div class="table-responsive">
                    <table class="table align-middle mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nom du projet</th>
                                <th>Montant alloué</th>
                                <th class="text-center">Nbr. d'activités</th>
                                <th>Date Début</th>
                                <th>Date Fin</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(project, i) in sector.projects" :key="project.id">
                                <th scope="row">{{ i + 1 }}</th>
                                <td>
                                    <router-link :to="'/cdn/projects/'+project.id">{{ project.name }}</router-link>
                                </td>
                                <td>{{ project.cost }} F CFA</td>
                                <td class="text-center">{{ project.activities.length }}</td>
                                <td>{{ project.date_start }}</td>
                                <td>{{ project.date_end }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <div class="bg-light p-2 mt-3 pt-3">
                <h6 class="font-20 text-danger">♣ Historique des responsables</h6>

                <div class="table-responsive">
                    <table class="table align-middle mb-0">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Responsable</th>
                            <th>Date Début</th>
                            <th>Date Fin</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(personal, i) in sector.personals" :key="personal.id">
                            <th scope="row">{{ i + 1 }}</th>
                            <td>
                                <router-link :to="'/cdn/users/'+personal.user.id">{{ personal.user.lastname + " "+personal.user.firstname }}</router-link>
                            </td>
                            <td>{{ personal.date_start }}</td>
                            <td>{{ personal.date_end }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            
            

            <div class="bg-light p-2 mt-3 pt-3">
                <h6 class="font-20 text-danger">♣ Rapports annuels</h6>

                <div class="table-responsive">
                  <table class="table align-middle mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nom du rapport</th>
                        <th>Fichier</th>
                        <th>Année</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(annual_report, i) in sector.annual_reports" :key="annual_report.id">
                        <th scope="row">{{ i + 1 }}</th>
                        <td>{{ annual_report.name }}</td>
                        <td><a :href="STORAGE_URL+annual_report.file" target="_blank" class="btn btn-primary rounded-pill">
                          <i class="bx bx-download font-size-16 align-middle mx-1 text-white"></i>
                          Télécharger
                        </a></td>
                        <td>{{ annual_report.year }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>


            <div class="bg-light p-3 pt-3 mt-3">
                <h6 class="font-20 text-danger">♣ Listes des indicateurs</h6>

                <div class="table-responsive">
                    <table class="table align-middle mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Libellé</th>
                                <th class="text-center">Type</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(parameter, i) in parameters" :key="parameter.id">
                                <th scope="row">{{ i + 1 }}</th>
          
                                <td class="text-center">{{ parameter.libelle }}</td>
                                <td class="text-center">{{ parameter.type }}</td>
                                <td class="hstack">
                                    <b-button class="btn-sm" @click="initItemIndicateur(parameter)" variant="success">
                                        <i
                                        class="bx bx-edit font-size-16 align-middle mx-1"
                                        ></i>
                                    </b-button>
                                    <b-button variant="danger" @click="initItemIndicateur(parameter, 'delete')" class="ms-2 btn-sm">
                                        <i class="bx bx-trash font-size-16 align-middle mx-1"></i>
                                    </b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="bg-light p-3 pt-3 mt-3">
              <b-button variant="primary" @click="downloadExcel" class="mb-3 ms-auto">
                Exporter en Excel
              </b-button>
                <h6 class="font-20 text-danger">♣ Suivi des indicateurs climatiques</h6>

                <div class="table-responsive">
                  <table class="table align-middle mb-0">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Projet</th>
                            <th v-for="indicator in parameters" :key="indicator.id">{{ indicator.libelle }}</th>
                            <th>Date</th>
                          </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(record, i) in records" :key="record.id">
                            <th scope="row">{{ i + 1 }}</th>
                            <td>
                                    <router-link :to="'/cdn/projects/'+record.project.id">{{ record.project.name }}</router-link>
                            </td>
                            <td v-for="indicator in record.response_form_parameters" :key="indicator.id">{{ indicator.value }}</td>
                            <td>{{ record.created_at }}</td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>

          </div>
        </div>
      </div>
    </div>



    <!-- Indicateur parametre -->


     <!-- ADD MODAL -->
     <b-modal
        v-model="doesAddOpenIndicateur"
        id="modal-center"
        size="lg"
        centered
        scrollable
        title="Ajouter un indicateur"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="addItemIndicateur()">
          <div class="row">
           
            <div class="col-md-6 mb-3">
                <label for="">Libellé de l'indicateur</label>
                <input type="text" required placeholder="Libellé de l'indicateur" v-model="libelle" class="form-control">
                <!-- <span class="text-danger mt-2" v-if="errors.libelle">{{ errors.libelle }}</span> -->
            </div>
            <div class="col-md-6 mb-3">
                <label for="">Type de l'indicateur</label>
                <select required v-model="type" class="form-control">
                  <option value="" disabled selected>Sélectionner le type</option>
                  <option value="ALPHANUMERIQUE">ALPHANUMERIQUE</option>
                  <option value="NUMERIQUE">NUMERIQUE</option>
                </select>
                <!-- <span class="text-danger mt-2" v-if="errors.libelle">{{ errors.libelle }}</span> -->
            </div>
            <div class="mb-3">
                <label for="">Obligation</label>
                <select required v-model="obligation" class="form-control">
                  <option value="" disabled selected>Sélectionner l'obligation </option>
                  <option value="obligatoire">Obligatoire</option>
                  <option value="non_obligatoire">Non obligatoire</option>
                </select>
                <!-- <span class="text-danger mt-2" v-if="errors.obligation">{{ errors.obligation }}</span> -->
            </div>
            
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>


    <!-- EDIT MODAL -->
    <b-modal
        v-model="doesEditOpenIndicateur"
        id="modal-center"
        centered
        scrollable
        size="lg"
        title="Modifier l'indicateur"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="editItemIndicateur()">
          <div class="row">
    
            <div class="col-md-6 mb-3">
                <label for="">Libellé de l'indicateur</label>
                <input type="text" required placeholder="Libellé de l'indicateur" v-model="libelle" class="form-control">
                <!-- <span class="text-danger mt-2" v-if="errors.libelle">{{ errors.libelle }}</span> -->
            </div>
            <div class="col-md-6 mb-3">
              <label for="">Type de l'indicateur</label>
              <select required v-model="type" class="form-control">
                  <option value="" disabled selected>Sélectionner le type</option>
                  <option value="ALPHANUMERIQUE">ALPHANUMERIQUE</option>
                  <option value="NUMERIQUE">NUMERIQUE</option>
              </select>
              <!-- <span class="text-danger mt-2" v-if="errors.type">{{ errors.type }}</span> -->
            </div>
            <div class="mb-3">
                <label for="">Obligation</label>
                <select required v-model="obligation" class="form-control">
                  <option value="" disabled selected>Sélectionner l'obligation </option>
                  <option value="obligatoire">Obligatoire</option>
                  <option value="non_obligatoire">Non obligatoire</option>
                </select>
                <!-- <span class="text-danger mt-2" v-if="errors.obligation">{{ errors.obligation }}</span> -->
            </div>

            
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>
    

    <!-- DELETE MODAL -->
    <b-modal
        v-model="doesDeleteOpenIndicateur"
        id="modal-center"
        title="Supprimer l'indicateur"
        title-class="font-18"
        hide-footer
        >
        <p class="mb-2">
            Voulez-vous vraiment supprimer l'indicateur <b>{{ parameter?.libelle }}</b> ?
        </p>
        <div class="hstack mt-2" v-if="!loading">
          <button class="btn btn-dark ms-auto" @click="doesDeleteOpenIndicateur = false">Annuler</button>
          <button class="ms-3 btn btn-danger" @click="deleteItIndicateur()">Supprimer</button>
        </div>
        <div class="text-center" v-else>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
    </b-modal>



  </Layout>
</template>
